.render-btn {
	margin-top: 10px;
}

.footer {
	padding-bottom: 50px;
	display: block;
	text-align: center;
}

.capture {
	margin-top: 30px;
}

#ReactGridGallery {
	min-height: 450px;
}

.delete-btn {
	float: right;
}

.custom-devices-head {
	font-weight: bold;
	margin-top: 10px;
}

.head {
	font-size: 40px;
	margin-bottom: 5px;
}

.subtitle {
	font-size: 18px;
	margin: 0;
	padding: 0;
}

.powered-by {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

.powered-by:hover {
	text-decoration: none;
}

.capture-logo {
	margin: 5px;
}

.capture {
	color: #7512FB !important;
	font-weight: bold;
	margin: 0;
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.desktop-only {
		display: none !important;
	}
}
